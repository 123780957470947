import './App.css';
// import { useLanguage } from './contexts/LanguageContext';
import { Routes, Route } from 'react-router-dom';
import Layout from './ui/Layout/Layout';
import { pages } from './ui/Layout/pages';
import Articles from './ui/pages/ua/Articles';

function App() {
  // const { getTranslation } = useLanguage();

  // {Object.entries(pages).map(([language, routes]) =>
  //   Object.entries(routes).map(([routeName, { path, title, Component }]) => (
  //     console.log(path, title, Component)
  //   ))
  // )}
  return (<Layout>
    <Routes>
      
      {Object.entries(pages).flatMap(([language, routes]) =>
        Object.entries(routes).map(([routeName, { path, title, Component }], index) => (
          <Route key={index} path={path} element={<Component />} />
        ))
      )}

    </Routes>

  </Layout>);
}

export default App;
